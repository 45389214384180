import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import currency from 'currency.js';
import { graphql, Link, useStaticQuery } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import * as React from 'react';
import * as Scroll from 'react-scroll';
import { Packages } from '../components/composites/packages';

import { Hero, ImageClipping } from '../components/containers/hero';
import { ImagePosition, ImageShape, ImageSplit } from '../components/containers/image-split';
import { Panel } from '../components/containers/panel';
import { Section, Style as SectionStyle } from '../components/containers/section';
import { SectionText } from '../components/containers/section-text';
import { buildButtonClassNames, Style as ButtonStyle, Type as ButtonType } from '../components/elements/button';
import { TextButton } from '../components/elements/text-button';
import { Site } from '../components/shells/site';
import { Heading, Level as HeadingLevel, Level } from '../components/typography/heading';
import { Seo } from '../components/utilities/seo';

export default (): React.ReactElement => {
    const { site } = useStaticQuery(
        graphql`
            query {
                site {
                    siteMetadata {
                        powerwalking {
                            pricing {
                                oncePerWeekMonthly
                                unlimitedMonthly
                            }
                        }
                    }
                }
            }
        `
    );

    const euro = (value: number) => currency(value, { symbol: '€', separator: '.', decimal: ',', pattern: '! #', precision: 0 });

    const pricing = {
        oncePerWeekMonthly: euro(site.siteMetadata.powerwalking.pricing.oncePerWeekMonthly),
        unlimitedMonthly: euro(site.siteMetadata.powerwalking.pricing.unlimitedMonthly),
    };

    return (
        <Site>
            <Seo
                title={'Powerwalking in regio Maastricht'}
                description={
                    'Verbeter je conditie en verbrand meer calorieën, zonder meteen naar de sportschool te moeten. Ideaal alternatief voor hardlopen, en ook geschikt voor beginners.'
                }
                openGraphImage={'/images/og-powerwalking.jpg'}
            />

            <Hero
                image={<StaticImage src={'../../assets/images/hero-powerwalking-1.jpg'} alt={''} />}
                imageClipping={ImageClipping.VerticalBlob}
                pageTitle={'Powerwalking in regio Maastricht'}
                pageText={'Wil je op een laagdrempelige, leuke manier buiten actief zijn? Dan is powerwalking iets voor jou!'}
            />

            <Section style={SectionStyle.Gradient}>
                <SectionText>
                    <Heading level={Level.Text}>
                        Doe mee voor{' '}
                        <span className={'inline-block px-2 py-1 bg-lemon rounded-lg'}>{pricing.oncePerWeekMonthly.format()}</span> per
                        maand
                    </Heading>
                    <div className={''}>
                        <p>
                            Voor de lessen powerwalking werk ik met een <strong>maandabonnement</strong>. Hiermee kun je zes keer per maand
                            mee voor <strong>{pricing.oncePerWeekMonthly.format()}</strong> per maand, of onbeperkt voor{' '}
                            <strong>{pricing.unlimitedMonthly.format()}</strong> per maand. Je kunt zelf kiezen welke dag je mee wilt.
                        </p>
                        <p className={'mt-4'}>
                            Je introductieles is <strong>altijd gratis</strong>. Dan kun je zonder verplichtingen eens kijken of het iets
                            voor jou is. Maakt je werkgever gebruik van{' '}
                            <a href={'https://benvitaal.nl/'} className={'underline'} target={'_blank'}>
                                benVitaal
                            </a>
                            ? Dan krijg je mogelijk korting op de lessen powerwalking en{' '}
                            <a href={'/leefstijlcoaching'} className={'underline'}>
                                leefstijlcoaching
                            </a>
                            .
                        </p>
                    </div>
                    <Scroll.Link
                        to={'register'}
                        smooth={'easeInOutQuint'}
                        className={classNames(buildButtonClassNames(ButtonStyle.Primary), 'mt-8 block font-bold')}>
                        Meld je aan
                    </Scroll.Link>
                </SectionText>
            </Section>

            <Section id={'register'} style={SectionStyle.Default}>
                <SectionText>
                    <Heading level={Level.Text}>Wanneer kom je mee?</Heading>
                    <p>
                        Ik geef powerwalking op vaste dagen en locaties. Meld je je van tevoren aan? Zo weet ik hoeveel mensen komen, en kan
                        ik je ook bereiken als een les niet door zou gaan.
                    </p>
                </SectionText>
                <div className={'grid md:grid-cols-2 gap-12 mt-12'}>

                    {/*<Panel className={''}>
                        <Heading level={Level.Text} className={'text-caribbean-800'}>
                            Maandag om 19:15
                        </Heading>
                        <span className={'block text-caribbean-800'}>Locatie: Belfort of Wolder (Maastricht)</span>
                        <span className={'inline-block px-3 py-1 mt-2 mb-1 bg-coral rounded-md text-sm text-white'}>
                            walking bootcamp
                        </span>
                        <div className={'mt-4'}>
                            <p>Op de even weeknummers starten we bij de Plus in Belfort, op de oneven weeknummers bij de kerk in Wolder.</p>
                            <p className={'mt-2'}>Wil je weten wat walking bootcamp precies is? Dit leg ik hierbeneden uit &#x1F447;</p>
                        </div>
                        <TextButton
                            type={ButtonType.Link}
                            style={ButtonStyle.Primary}
                            url={`https://wa.me/31641777848?text=${encodeURIComponent(
                                'Hoi! Ik wil graag mee powerwalken op maandag om 19:15'
                            )}`}
                            caption={'Aanmelden via WhatsApp'}
                            callout={false}
                            className={'mt-8'}
                        />
                        <p className={'mt-4 text-xs text-gray-700'}>
                            Bij aanmelden ga je akkoord met de{' '}
                            <Link to={'/voorwaarden/powerwalking'} className={'underline'}>
                                voorwaarden
                            </Link>
                        </p>
                    </Panel>*/}

                    <Panel className={''}>
                        <Heading level={Level.Text} className={'text-caribbean-800'}>
                            Woensdag om 19:15
                        </Heading>
                        <span className={'text-caribbean-800'}>Locatie: Wolder of Villapark (Maastricht)</span>
                        <div className={'mt-4'}>
                            <p>
                                Op de even weeknummers starten we bij de kerk in Wolder, op de oneven weeknummers bij Tapijn (terrein
                                Tapijnkazerne). Met oefeningen erbij is dit ongeveer een uurtje.
                            </p>
                        </div>
                        <TextButton
                            type={ButtonType.Link}
                            style={ButtonStyle.Primary}
                            url={`https://wa.me/31641777848?text=${encodeURIComponent(
                                'Hoi! Ik wil graag mee powerwalken op woensdag om 19:15'
                            )}`}
                            caption={'Aanmelden via WhatsApp'}
                            callout={false}
                            className={'mt-8'}
                        />
                        <p className={'mt-4 text-xs text-gray-700'}>
                            Bij aanmelden ga je akkoord met de{' '}
                            <Link to={'/voorwaarden/powerwalking'} className={'underline'}>
                                voorwaarden
                            </Link>
                        </p>
                    </Panel>

                    {/*<Panel className={''}>
                        <Heading level={Level.Text} className={'text-caribbean-800'}>
                            Donderdag om 9:00
                        </Heading>
                        <span className={'text-caribbean-800'}>Locatie: Berg en Terblijt</span>
                        <div className={'mt-4'}>
                            <p>
                                Vanuit het Vöske wandelen we om en rond Berg en Terblijt en omstreken. We maken bij de krachtoefeningen
                                gebruik van het landschap. Ook hier kun je rekenen op een uur actief bezig zijn.
                            </p>
                        </div>
                        <TextButton
                            type={ButtonType.Link}
                            style={ButtonStyle.Primary}
                            url={`https://wa.me/31641777848?text=${encodeURIComponent(
                                'Hoi! Ik wil graag mee powerwalken op donderdag om 9:00'
                            )}`}
                            caption={'Aanmelden via WhatsApp'}
                            callout={false}
                            className={'mt-8'}
                        />
                        <p className={'mt-4 text-xs text-gray-700'}>
                            Bij aanmelden ga je akkoord met de{' '}
                            <Link to={'/voorwaarden/powerwalking'} className={'underline'}>
                                voorwaarden
                            </Link>
                        </p>
                    </Panel>*/}

                    <Panel className={''}>
                        <Heading level={Level.Text} className={'text-caribbean-800'}>
                            Vrijdag om 9:00
                        </Heading>
                        <span className={'block text-caribbean-800'}>Locatie: Berg en Terblijt</span>
                        {/*<span className={'inline-block px-3 py-1 mt-2 mb-1 bg-coral rounded-md text-sm text-white'}>
                            walking bootcamp
                        </span>*/}
                        <div className={'mt-4'}>
                            <p>
                                Vanuit het Vöske wandelen we om en rond Berg en Terblijt en omstreken. We maken bij de krachtoefeningen
                                gebruik van het landschap. Ook hier kun je rekenen op een uur actief bezig zijn.
                            </p>
                        </div>
                        <TextButton
                            type={ButtonType.Link}
                            style={ButtonStyle.Primary}
                            url={`https://wa.me/31641777848?text=${encodeURIComponent(
                                'Hoi! Ik wil graag mee powerwalken op vrijdag om 9:00'
                            )}`}
                            caption={'Aanmelden via WhatsApp'}
                            callout={false}
                            className={'mt-8'}
                        />
                        <p className={'mt-4 text-xs text-gray-700'}>
                            Bij aanmelden ga je akkoord met de{' '}
                            <Link to={'/voorwaarden/powerwalking'} className={'underline'}>
                                voorwaarden
                            </Link>
                        </p>
                    </Panel>

                    <Panel className={''}>
                        <Heading level={Level.Text} className={'text-caribbean-800'}>
                            Zaterdag om 9:00
                        </Heading>
                        <span className={'text-caribbean-800'}>Locatie: Chalet Bergrust (Maastricht)</span>
                        <div className={'mt-4'}>
                            <p>
                                Vanuit Chalet Bergrust gaan we de Sint Pietersberg op en sporten we in het mooie landschap. Net zoals op de
                                vrijdag zijn we een uur bezig.
                            </p>
                        </div>
                        <TextButton
                            type={ButtonType.Link}
                            style={ButtonStyle.Primary}
                            url={`https://wa.me/31641777848?text=${encodeURIComponent(
                                'Hoi! Ik wil graag mee powerwalken op zaterdag om 9:00'
                            )}`}
                            caption={'Aanmelden via WhatsApp'}
                            callout={false}
                            className={'mt-8'}
                        />
                        <p className={'mt-4 text-xs text-gray-700'}>
                            Bij aanmelden ga je akkoord met de{' '}
                            <Link to={'/voorwaarden/powerwalking'} className={'underline'}>
                                voorwaarden
                            </Link>
                        </p>
                    </Panel>

                    {/* <Panel style={PanelStyle.Light}>
                        <Heading level={Level.Text} className={'text-caribbean-800'}>
                            Andere dag of tijd?
                        </Heading>
                        <div className={'mt-4'}>
                            <p>
                                De dinsdag en zaterdag staat vast, maar er kunnen nog extra dagen of tijden bij komen. Zou jij op een
                                bepaalde dag of tijd wel kunnen? Laat me dit dan weten!
                            </p>
                        </div>
                        <TextButton
                            type={ButtonType.Link}
                            style={ButtonStyle.Primary}
                            url={'https://wa.me/31641777848'}
                            caption={'Stuur een WhatsApp'}
                            callout={false}
                            className={'mt-8'}
                        />
                        <p className={'mt-4 text-xs text-gray-700'}>
                            Of stuur me een{' '}
                            <a href={'mailto:vicky@fitfoodforty.nl?subject=Andere%20dag%20voor%20powerwalking'} className={'underline'}>
                                e-mail
                            </a>
                            , dat kan ook natuurlijk
                        </p>
                     </Panel> */}
                </div>
            </Section>

            {/*<Section style={SectionStyle.Odd}>
                <SectionText>
                    <Heading level={Level.Text}>Wat is de 'walking bootcamp'?</Heading>
                    <p>We powerwalken in verschillende tempo’s en op verschillende plekken worden er cardio en krachtoefeningen gedaan. Ben jij niet van het hardlopen en wil je wel actief bezig zijn in de buitenlucht? Meld je dan vanaf vrijdag 5 januari aan voor een gratis proefles. Walking bootcamp is voor elke leeftijd geschikt, en de conditie wordt gaandeweg opgebouwd.</p>
                </SectionText>
            </Section>*/}

            <Section style={SectionStyle.Default}>
                <SectionText>
                    <Heading level={Level.Text}>
                        De XL-wandeling{' '}
                        <span className={'relative -top-1.5 inline-block px-3 py-1 ml-2 bg-coral rounded-md text-sm text-white'}>
                            nieuw
                        </span>
                    </Heading>
                    <p>
                        Iedere twee maanden wordt er een <strong>XL wandeling</strong> buiten Maastricht georganiseerd. Dit is powerwalking
                        met een afstand van 10 kilometer. Wil je meer weten of je alvast aanmelden?{' '}
                        <a
                            href={'https://wa.me/31641777848'}
                            target={'_blank'}
                            title={'App me via WhatsApp!'}
                            rel={'noreferrer'}
                            className={'underline'}>
                            App me via WhatsApp
                        </a>{' '}
                    </p>
                    <TextButton
                        type={ButtonType.Link}
                        url={'mailto:vicky@fitfoodforty.nl'}
                        caption={'Vragen? Mail me!'}
                        className={'mt-6'}
                    />
                </SectionText>
            </Section>

            <Section style={SectionStyle.Callout}>
                <SectionText>
                    <Heading level={Level.Text}>Twijfels? Eerste les is gratis 😘</Heading>
                    <div className={''}>
                        <p>
                            Lijkt powerwalking je leuk? Meld je aan, en kom eens een keertje gratis mee. Je hebt eigenlijk alleen
                            sportschoenen nodig. Je hoeft geen bepaalde conditie te hebben.
                        </p>
                    </div>
                </SectionText>
            </Section>

            <ImageSplit
                imageShape={ImageShape.Circle}
                imagePosition={ImagePosition.Right}
                content={
                    <>
                        <Heading level={Level.Text}>Over Vicky</Heading>
                        <div className={''}>
                            <p>
                                Hoi, ik ben Vicky! Voedingsdeskundige, geaccrediteerd{' '}
                                <Link to={'/leefstijlcoaching'} className={'underline'}>
                                    leefstijlcoach
                                </Link>{' '}
                                én natuurlijk <strong>gecertificeerd powerwalkingcoach</strong>. Met deze lessen wil ik je graag helpen om
                                op een laagdrempelige manier tot een actievere levensstijl te komen.
                            </p>
                        </div>
                        <TextButton
                            type={ButtonType.Link}
                            url={'mailto:vicky@fitfoodforty.nl'}
                            caption={'Vragen? Mail me!'}
                            className={'mt-6'}
                        />
                        <div className={'mt-4 text-xs text-gray-700'}>
                            Of app me via WhatsApp:{' '}
                            <a
                                href={'https://wa.me/31641777848'}
                                target={'_blank'}
                                title={'App me gerust via WhatsApp!'}
                                rel={'noreferrer'}
                                className={'underline'}>
                                06 4177 7848
                            </a>{' '}
                            <FontAwesomeIcon icon={['fab', 'whatsapp']} size={'1x'} className={'ml-0.5'} />
                        </div>
                    </>
                }
                image={<StaticImage src={'../../assets/images/section-split-vicky-5.jpg'} alt={''} />}
            />

            <Section style={SectionStyle.Light}>
                <SectionText>
                    <Heading level={Level.Text}>Powerwalken? Is dat niet gewoon snelwandelen?</Heading>
                    <p>
                        Nee, zeker niet. En het is ook geen Nordic Walking :) Powerwalking is op hoger tempo wandelen, maar in mijn lessen
                        combineer ik dit ook met krachtoefeningen.
                    </p>
                    <p className={'mt-4'}>
                        Met powerwalking verbrand je veel meer calorieën dan met gewoon wandelen, zonder meteen naar de sportschool te
                        moeten. Dit is dus ook de ideale oplossing als hardlopen te belastend is voor je lichaam.
                    </p>
                </SectionText>
            </Section>

            <Section>
                <div className={'grid md:grid-cols-2 gap-12 mt-12'}>
                    <div>
                        <Heading level={Level.Text}>Hoe kun je meedoen?</Heading>
                        <div className={'prose mt-4'}>
                            <ol className={'pl-4'}>
                                <li>De eerste stap heb je al gezet, want je bent dit aan het lezen. Wat goed!</li>
                                <li>
                                    Meld je{' '}
                                    <a
                                        href={'https://wa.me/31641777848'}
                                        target={'_blank'}
                                        title={'App me gerust via WhatsApp!'}
                                        rel={'noreferrer'}
                                        className={'underline'}>
                                        via WhatsApp
                                    </a>{' '}
                                    aan voor een les.
                                </li>
                                <li>Zorg dat je er op tijd bent, en we kunnen van start!</li>
                            </ol>
                        </div>
                    </div>
                    <div>
                        <Heading level={Level.Text}>Belangrijk om te weten</Heading>
                        <div className={'prose mt-4'}>
                            <ul className={'pl-4'}>
                                <li>
                                    Zorg voor schoenen waar je goed op kunt lopen, bijvoorbeeld hardloopschoenen. Wandelschoenen zijn niet
                                    geschikt.
                                </li>
                                <li>
                                    Heb je lichamelijke klachten waardoor je misschien niet goed mee kunt doen, bijvoorbeeld last van je
                                    knieën? Maak dan zelf een realistische inschatting. Twijfel je? Neem dan <strong>vooraf</strong>{' '}
                                    <a href={'mailto:vicky@fitfoodforty.nl'} className={'underline'}>
                                        contact
                                    </a>{' '}
                                    met me op en/of overleg met je huisarts.
                                </li>
                                <li>
                                    Op de lessen zijn mijn{' '}
                                    <Link to={'/voorwaarden/powerwalking'} className={'underline'}>
                                        voorwaarden
                                    </Link>{' '}
                                    van toepassing.
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </Section>

            <Section>
                <SectionText>
                    <Heading level={HeadingLevel.Second}>Pakketten</Heading>
                    <p>Wil je alles in één keer aanpakken? Kies dan voor een van mijn pakketten. Hiermee krijg je een combinatie van <a href={'/leefstijlcoaching'} className={'underline'}>leefstijlcoaching</a>, <a href={'/trainingen'} className={'underline'}>trainingen</a> en powerwalking voor een pakketprijs. Wel zo makkelijk!</p>
                </SectionText>
                <Packages className={'mt-12'} />
            </Section>
        </Site>
    );
};
